input:not([value=""]):not(:focus):invalid+label {
    top: 4px;
    font-size: 13px;
}

.selectInput{
    top: 4px !important;
    font-size: 13px !important;
}
.sold-inputfield{
    grid-column-start: 1;
    grid-column-end: 5;
}