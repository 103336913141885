* {
  scrollbar-width: auto;
  scrollbar-color: #b68514 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #b68514;
  /* border-radius: 5px;   */
  border: 3px solid #fff;
}